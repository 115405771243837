/* Header.css */

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #f8f8f8; /* Whitish background */
  color: #333; /* Dark text color */
}

.logo a {
  color: #e60000; /* Red color for RK Industries */
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 1rem;
  margin: 0;
  padding: 0;
}

.nav-links li {
  margin: 0;
}

.nav-links a {
  color: #555; /* Dark color for other links */
  text-decoration: none;
}
.industry img {
  width: 100%; /* Adjust as needed */
  border-radius: 8px; /* Add styling as needed */
}
/* Media Query for Responsive Design */
.logo {
  display: flex;
  align-items: center;
}

.logo img {
  width: 60px; /* Set the width of your logo */
  height: auto; /* Maintain aspect ratio */
  margin-right: -5px; /* Optional: Add some space between the logo and text */
}

.logo a {
  text-decoration: none;
  color: #de2f2f; /* Red color for "RK Industries" */
  font-size: 1.5rem; /* Optional: Adjust font size */
}

@media (max-width: 768px) {
  .header-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-links {
    margin-top: 1rem;
  }
}
