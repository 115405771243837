/* Home.css */

/* Reset some default styles */
body, h1, h2, h3, p {
  margin: 0;
  padding: 0;
}


/* Global styles */
.container {
  max-width: 1200px;
  margin: 0 auto;
  /* padding: 0 20px; */
}

/* Hero section */
.hero-section {
  position: relative;
  height: 400px;
  background: url('./images/IMG-20231223-WA0005.jpg') center/cover;
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Adjust the overlay color and opacity */
}

.hero-title {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 1.2em;
  color: rgb(246, 249, 251);
}

/* Welcome section */
.welcome-section {
  background-color: #f8f8f8; /* Light gray background color */
  padding: 40px 0;
}

.welcome-title {
  font-size: 2em;
  margin-bottom: 20px;
}

.welcome-message {
  font-size: 1.2em;
}

/* About section */
.about-section {
  padding: 40px 0;
  background-color: #f8f8f8; /* Light gray background color */

}

.about-section h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.about-section p {
  font-size: 1.2em;
}

 

/* Responsive styles */
@media (max-width: 768px) {
  .industries-section h2 {
    font-size: 1.5em; /* Adjust font size for smaller screens */
    
  }
}

/* IndustriesSection.css */

.industries-section {
  padding: 40px 0;
  background-color: #f0f0f0; /* Light gray background color */
}

.i-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;

  
}

.industry {
  margin-bottom: 40px;
  text-align: center;
}

.image-container {
  display: inline-block;
  width: 48%; /* Adjust as needed */
  padding: 2px;

}

.image-container img {
  width: 300px;
  height: 300px;
  /* max-width: 100% ; */
  border-radius: 8px;
  margin-bottom: 10px;
}

.industry h3 {
  font-size: 1.8rem;
  margin: 15px 0;
}

.industry p {
  font-size: 1.1rem;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .image-container {
    width: 100%;
    display: block;
  }
  
}



/* Adjust other responsive styles as needed */

/* ... (Previous styles) */

/* Pictures section */
.pictures-section {
  padding: 40px 0;
  background-color: #f8f8f8; /* Light gray background color */

}

.pictures-section h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.trending-card {
  position: relative;
  overflow: hidden;
  
}

.trending-card img {
  width: 375px;
  height: 300px;
  transition: transform 0.3s ease-in-out;
  border-radius: 8px;
  
}

.trending-card:hover img {
  transform: scale(1.1);
}

/* Mission section */
.mission-section {
  background-color: #f8f8f8; /* Light gray background color */
  padding: 40px 0;
}

/* ... (Remaining styles) */

/* Responsive styles */
@media (max-width: 768px) {
  /* ... (Responsive styles) */
}

/* Key Features section */
.key-features {
  padding: 40px 0;
  background-color: #f8f8f8; /* Light gray background color */

}

.key-features h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.features-content {
  display: flex;
  justify-content: space-between;
}

.feature-item {
  width: 30%; /* Adjust the width based on your design */
}

/* Home.css */

/* ... (Previous styles) */
/* Home.css */

/* ... (Previous styles) */

/* Contact.css */

/* .contact-section{
  background-color: rgb(52, 50, 50);
} */
.contact-details {
  display: flex;
  flex-direction: column;
  /* color:rgb(120, 124, 128) */
}

.contact-details div {
  margin-bottom: 15px;
  /* color:rgb(120, 124, 128) */

}

.contact-key {
  font-weight: bold;
  margin-right: 8px;
  /* color:rgb(120, 124, 128) */

}



/* Responsive styles */
@media (max-width: 768px) {
  .hero-section {
    height: 300px;
  }

  .hero-title {
    font-size: 2em;
  }

  .subtitle {
    font-size: 1em;
  }

  .welcome-title {
    font-size: 1.5em;
  }

  .welcome-message {
    font-size: 1em;
  }

  .about-section h2 {
    font-size: 1.5em;
  }

  .about-section p {
    font-size: 1em;
  }

  .industries-section h2 {
    font-size: 1.5em;
  }

  .pictures-section h2 {
    font-size: 1.5em;
  }

  .trending-card img {
    width: 100%;
    height: auto;
  }

  .mission-section h2 {
    font-size: 1.5em;
  }

  .mission-section p {
    font-size: 1em;
  }

  .key-features h2 {
    font-size: 1.5em;
  }

  .features-content {
    flex-direction: column;
  }

  .feature-item {
    width: 100%;
    margin-bottom: 20px;
  }

  .contact-section h2 {
    font-size: 1.5em;
  }
}
