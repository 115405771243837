/* Contact.css */

.new-contact-container {
    background-color: #f2f2f2;
    color: #333;
    padding: 20px;
  }
  
  .new-contact-section {
    text-align: center;
  }
  
  .new-container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .new-contact-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .new-contact-item {
    flex: 0 0 48%;
    margin-bottom: 20px;
  }
  
  .new-contact-item h3 {
    color: #007bff;
  }
  
  .new-map-section {
    margin-top: 20px;
  }
  
  .new-map-container {
    position: relative;
    overflow: hidden;
    padding-top: 75%; /* 4:3 Aspect Ratio */
  }
  
  .new-map-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  
  /* Responsive Styles */
  @media screen and (max-width: 768px) {
    .new-contact-item {
      flex: 0 0 100%;
    }
  }
  